import { combineReducers } from "redux";
import auth from "./auth/authReducer";
import user from "./user/userReducer";
import role from "./role/roleReducer";
import dealer from "./dealer/dealerReducer";
import transaction from "./transaction/transactionReducer";
import socialMediaRequest from "./socialMedia/socialMediaReducer";
import serviceHistory from "./serviceHistory/serviceHistoryReducer";
import photoShootRequest from "./photoshootRequest/photoshootRequestReducer";
import rtoCasesRequest from "../store/rto-services/rtoCases/rtoCasesReducer";
import pickUpRequest from "../store/rto-services/pickupRequest/pickupRequestReducer";
import challanRequest from "../store/rto-services/challanRequest/challanRequestReducer";
import DMRequest from "../store/digitalMarketingRequest/requestReducer";
import websiteDevelopmentRequest from "../store/websiteDevelopment/websiteDevelopmentReducer";
import insuranceRequest from "../store/financial-services/insuranceRequest/insuranceRequestReducer";
import loanRequest from "../store/financial-services/loanRequest/loanRequestReducer";
import creativeMakingRequest from "./creativeMakingRequest/creativeMakingRequestReducer";
import d2d from "./d2d/d2dReducer";
import dealerSubUser from "./dealerSubUser/subUserReducer";
import reelsReducers from "./reels/reelsReducers";

export default combineReducers({
  auth,
  user,
  role,
  dealer,
  DMRequest,
  transaction,
  loanRequest,
  pickUpRequest,
  challanRequest,
  serviceHistory,
  rtoCasesRequest,
  insuranceRequest,
  photoShootRequest,
  socialMediaRequest,
  creativeMakingRequest,
  websiteDevelopmentRequest,
  d2d,
  dealerSubUser,
  reelsReducers
});
